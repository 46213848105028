import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { WindowLocation } from '@reach/router'

import { Button } from '@paljs/ui/Button'
import { InputGroup } from '@paljs/ui/Input'
import { Checkbox } from '@paljs/ui/Checkbox'
import Alert from '@paljs/ui/Alert'

import Auth, { Group } from '../../components/Auth'
import Loading from '../../components/Loading'

import useAuth from '../../util/useAuth'
import { createLocalStorage } from '../../util/helpers'

interface UserData {
  identifier: string
  password: string
}

type LoginProps = { location: WindowLocation<{ redirect: string; register: boolean }> }
const Login: React.FC<LoginProps> = ({ location }) => {
  const { state: routeState } = location
  const { login, getUser } = useAuth()
  const [remember, setRemember] = useState(false)
  const [defaultValues, setDefaultValues] = useState<UserData>({ identifier: '', password: '' })

  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(true)

  const userStorage = createLocalStorage('remember', '')
  const redirect = !routeState ? '/' : !routeState.redirect ? '/' : `${routeState.redirect}`

  useEffect(() => {
    getUser()
      .then(() => navigate(redirect))
      .catch(() => {
        setLoading(false)
        const userResult = userStorage.get()
        if (userResult) {
          const user = JSON.parse(userResult) as UserData
          if (user.identifier) {
            setRemember(true)
            setDefaultValues(user)
          }
        }
      })
  }, [])

  useEffect(() => {
    navigate(location.pathname, { state: {}, replace: true })
  }, [routeState])

  const onCheck = (value: boolean) => setRemember(value)

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setError('')
    setLoading(true)

    const values: UserData = {
      identifier: event.target['identifier'].value,
      password: event.target['password'].value,
    }

    try {
      await login(values)
      if (remember) userStorage.set(JSON.stringify(values))
      else userStorage.clear()
      window.location.replace(redirect)
    } catch (e) {
      const {
        response: {
          data: {
            message: [
              {
                messages: [error],
              },
            ],
          },
        },
      } = e
      setLoading(false)
      setError(error.message)
    }
  }

  if (loading) return <Loading isAuth />
  return (
    <Auth title='Sign In' subTitle='Hello! Sign in with your email'>
      {!!routeState?.register && (
        <Alert closable status='Info'>
          You are a rockstar, you can join us now.
        </Alert>
      )}
      <form method='post' onSubmit={handleSubmit}>
        <InputGroup fullWidth>
          <input type='email' name='identifier' defaultValue={defaultValues.identifier} placeholder='Email Address' />
        </InputGroup>
        <InputGroup fullWidth>
          <input type='password' name='password' defaultValue={defaultValues.password} placeholder='Password' />
        </InputGroup>
        <Group>
          <Checkbox onChange={onCheck} checked={remember}>
            Remember me
          </Checkbox>
          <Link to='/auth/request-password'>Forgot Password?</Link>
        </Group>
        <Button status='Success' type='submit' shape='SemiRound' fullWidth>
          Sign in
        </Button>
        <Button status='Info' type='button' shape='SemiRound' fullWidth onClick={() => navigate('/auth/register')}>
          Or Sign up (if you are not a rockstar yet)
        </Button>

        {!!error && (
          <Alert closable status='Danger' onClose={() => setError('')}>
            {error}
          </Alert>
        )}
      </form>
    </Auth>
  )
}
export default Login
